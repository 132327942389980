import React, { useState, useEffect } from 'react';
import axios from 'api/axios';
import useAuth from 'hooks/useAuth';

import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

function Payment() {
  const { id } = useParams();
  const { auth } = useAuth();
  const accessToken = auth.accessToken;

  const [employees, setEmployees] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    selected_employee: '',
    total_rate: '',
    payed_rate: '',
    payment_status: '',
  });
  const { selectedEmployee, total_rate, payed_rate, payment_status } = formData;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('/employee', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${accessToken}`,
          },
        });
        console.log({ api: response?.data?.data });
        setEmployees(response?.data?.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchEmployees();
  }, [accessToken]);

  useEffect(() => {
    fetchAssignments().then((response) => {
      setAssignment(response);
      setFormData({
        selectedEmployee: assignment.employee,
        total_rate: assignment.total_rate,
        payed_rate: assignment.payed_rate,
        payment_status: assignment.payment_status
      });
    })
  }, []);

  const fetchAssignments = async () => {
    try {
      const response = await axios.get('/assign', {
        params: {
          id: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
        },
      });
      console.log({ api: response?.data?.data });
      return response?.data?.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const handleAssign = async () => {
    const assignmentData = {
      employee: parseInt(assignment.employee.id, 10),
      events: parseInt(assignment.events, 10),
      total_rate: parseInt(assignment.total_rate, 10),
      payed_rate: parseInt(assignment.payed_rate, 10),
      payment_status: assignment.payment_status,
    };

    console.log({ assignmentData });

    // Convert assignmentData object to FormData
    const formData = new FormData();
    for (const key in assignmentData) {
      formData.append(key, assignmentData[key]);
    }

    if (
      assignmentData?.employee &&
      assignmentData?.total_rate &&
      assignmentData?.payed_rate &&
      assignmentData?.payment_status
    ) {
      try {
        const response = await axios.put('/assign', formData, {
          params: {
            id: id,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${accessToken}`,
          },
        });

        // Reload the page after successful assignment creation
        setSuccess(true);
        setFormData({ selectedEmployee: '', total_rate: '', payed_rate: '', payment_status: '' });

        fetchAssignments()
        //   .then(assignedEmployees => {
        //   const filteredAssignments = assignedEmployees.filter(assignment => assignment.role !== null);
        //   setAssignments(filteredAssignments);
        // });
      } catch (error) {
        console.error('Error creating assignment:', error.message);
      }
    } else {
      console.error('Please fill all the required fields.');
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={4}>
        <Spinner size="lg" color="teal.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="subtle">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Container maxW="4xl" py={16} /* Increased maxW and py values for a bigger container */>
      <VStack spacing={4} align="start">
        <Heading as="h1" size="xl" mt={8}>
          Empleados Postulados
        </Heading>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Alias</Th>
                <Th>Rol</Th>
              </Tr>
            </Thead>
            <Tbody>
              {assignments.map(assignment =>
                assignment?.assignment_status === 'POSTULADO' ? (
                  <Tr>
                    <Td>{assignment?.employee?.employee_alias || '-'}</Td>
                    <Td>{assignment?.role}</Td>
                  </Tr>
                ) : null,
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Heading as="h1" size="xl" mt={8}>
          Editar Pago a Empleados
        </Heading>
        {success && (
          <Alert status="success">
            <AlertIcon />
            Cambios realizados correctamente!
          </Alert>
        )}

        {/* FORM START */}

        <VStack spacing={4} align="start" w="full">
          <FormControl>
            <FormLabel>Empleado Seleccionado</FormLabel>
            <h1>
              {`${assignment.employee.name}-${assignment.employee.employee_alias}`}
            </h1>
            {/* {employees.map(employee => ( */}
            {/*   <option key={employee.id} value={employee.id}> */}
            {/*     {employee.name} {employee.surname} */}
            {/*   </option> */}
            {/* ))} */}
          </FormControl>
          <FormControl>
            <FormLabel>Monto Total</FormLabel>
            <Textarea
              required
              value={assignment.total_rate}
              onChange={e => setAssignment({ ...assignment, total_rate: e.target.value })}
              placeholder="Ingresar Monto"
              rows={1}
            ></Textarea>
          </FormControl>
          <FormControl>
            <FormLabel>Monto Pago</FormLabel>
            <Textarea
              required
              value={assignment.payed_rate}
              onChange={e => setAssignment({ ...assignment, payed_rate: e.target.value })}
              placeholder="Ingresar Monto"
              rows={1}
            ></Textarea>
          </FormControl>
          <FormControl>
            <FormLabel>Estado del Pago</FormLabel>
            <Textarea
              required
              value={assignment.payment_status}
              onChange={e => setAssignment({ ...assignment, payment_status: e.target.value })}
              placeholder="Ingresar Estado"
              rows={1}
            ></Textarea>
          </FormControl>
          <Button colorScheme="teal" onClick={handleAssign}>
            Asignar
          </Button>
        </VStack>

        {/* FORM END */}

        {/* <Heading as="h2" size="lg" mt={8}> */}
        {/*   Asignaciones */}
        {/*   </Heading> */}
        {/*   <VStack spacing={4} align="start" w="full"> */}
        {/*     <VStack spacing={3} align="start" w="full"> */}
        {/*       <TableContainer> */}
        {/*         <Table variant="simple"> */}
        {/*           <Thead> */}
        {/*             <Tr> */}
        {/*               <Th>Nombre</Th> */}
        {/*               <Th>Alias</Th> */}
        {/*               <Th>Status</Th> */}
        {/*               <Th>Monto Total</Th> */}
        {/*               <Th>Estado Pago</Th> */}
        {/*             </Tr> */}
        {/*           </Thead> */}
        {/*           <Tbody> */}
        {/*             {assignments.map(assignment => */}
        {/*               assignment?.assignment_status == 'POSTULADO' ? ( */}
        {/*                 <Tr> */}
        {/*                   <Td> */}
        {/*                     {assignment?.employee?.name} {assignment?.employee?.surname} */}
        {/*                   </Td> */}
        {/*                   <Td fontWeight="bold">{assignment?.employee?.employee_alias || '-'}</Td> */}
        {/*                   <Td>{assignment?.assignment_status}</Td> */}
        {/*                   <Td> {assignment?.total_rate}</Td> */}
        {/*                   <Td>{assignment?.payment_status}</Td> */}
        {/*                 </Tr> */}
        {/*               ) : null, */}
        {/*             )} */}
        {/*           </Tbody> */}
        {/*         </Table> */}
        {/*       </TableContainer> */}
        {/*     </VStack> */}
        {/*   </VStack> */}
      </VStack>
    </Container >
  );
}

export default Payment;
