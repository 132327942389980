import { Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { translateKey } from "library/general/Translation";
import { filter_obj } from 'library/general/ArrayFilter'
import { api_get_user_info } from 'api/apiModels'
import useAuth from 'hooks/useAuth';
import { useState, useEffect } from 'react';

const DISPLAY_FIELDS = ['employee_id', 'name', 'surname', 'cuit', 'birthday', 'cellphone_no', 'has_car', 'license_number', 'cbu', 'alias'];

const flexitem = obj => Object.entries(obj).map(([key, value]) => (
  <Flex align='center' mb='18px'>
    <Text fontSize='md' color='gray.500' fontWeight='bold' me='10px'>
      {translateKey(key)}:{" "}
    </Text>
    <Text fontSize='md' color='gray.500' fontWeight='400'>
      {value}
    </Text>
  </Flex>
));

function UserInfoComponent() {
  const [apiData, setApiData] = useState([]);
  const {auth} = useAuth();

  useEffect(() => {
    api_get_user_info(auth.accessToken)
      .then(res => {
        const apiData = filter_obj(res, DISPLAY_FIELDS);
        setApiData(apiData);
      });
  }, [auth]);

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color='black' fontWeight='bold'>
          Información
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column'>
          {flexitem(apiData)}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default UserInfoComponent;
