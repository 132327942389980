import React, { useState, useEffect } from 'react';
import axios from 'api/axios';
import useAuth from 'hooks/useAuth';

import {
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';

function Assignment() {
  const { id } = useParams();
  const { auth } = useAuth();
  const accessToken = auth.accessToken;

  const [employees, setEmployees] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [success, setSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    selectedEmployee: '',
    assignmentStatus: '',
    role: '',
    observation: '',
  });
  const { selectedEmployee, assignmentStatus, role, observation } = formData;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('/employee', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${accessToken}`,
          },
        });
        console.log({ api: response?.data?.data });
        setEmployees(response?.data?.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchEmployees();
  }, [accessToken]);

  useEffect(() => {
    fetchAssignments().then(assignedEmployees => {
      const filteredAssignments = assignedEmployees.filter(assignment => assignment.role !== null);
      setAssignments(filteredAssignments);
    });
  }, []);

  const fetchAssignments = async () => {
    try {
      const response = await axios.get('/assign', {
        params: {
          events: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
        },
      });
      console.log({ api: response?.data?.data });
      return response?.data?.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const handleAssign = async () => {
    const assignmentData = {
      employee: parseInt(selectedEmployee, 10),
      events: parseInt(id, 10),
      assignment_status: assignmentStatus,
      role: role,
      observation: observation,
    };

    // Convert assignmentData object to FormData
    const formData = new FormData();
    for (const key in assignmentData) {
      formData.append(key, assignmentData[key]);
    }

    if (
      assignmentData?.employee &&
      assignmentData?.events &&
      assignmentData?.assignment_status &&
      assignmentData?.role
    ) {
      try {
        const response = await axios.post('/assign', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Token ${accessToken}`,
          },
        });

        // Reload the page after successful assignment creation
        setSuccess(true);
        setFormData({ selectedEmployee: '', assignmentStatus: '', role: '', observation: '' });

        fetchAssignments().then(assignedEmployees => {
          const filteredAssignments = assignedEmployees.filter(assignment => assignment.role !== null);
          setAssignments(filteredAssignments);
        });
      } catch (error) {
        console.error('Error creating assignment:', error.message);
      }
    } else {
      console.error('Please fill all the required fields.');
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={4}>
        <Spinner size="lg" color="teal.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="subtle">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Container maxW="4xl" py={16} /* Increased maxW and py values for a bigger container */>
      <VStack spacing={4} align="start">
        <Heading as="h1" size="xl" mt={8}>
          Empleados Postulados
        </Heading>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Alias</Th>
                <Th>Rol</Th>
              </Tr>
            </Thead>
            <Tbody>
              {assignments.map(assignment =>
                assignment?.assignment_status == 'POSTULADO' ? (
                  <Tr>
                    <Td>{assignment?.employee?.employee_alias || '-'}</Td>
                    <Td>{assignment?.role}</Td>
                  </Tr>
                ) : null,
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Heading as="h1" size="xl" mt={8}>
          Lista Empleados
        </Heading>
        {success && (
          <Alert status="success">
            <AlertIcon />
            Cambios realizados correctamente!
          </Alert>
        )}
        <VStack spacing={4} align="start" w="full">
          <FormControl>
            <FormLabel>Seleccionar un Empleado</FormLabel>
            <Select
              required
              value={selectedEmployee}
              onChange={e => setFormData({ ...formData, selectedEmployee: e.target.value })}
              placeholder="Seleccionar un Empleado"
            >
              {employees.map(employee => (
                <option key={employee.id} value={employee.id}>
                  {employee.name} {employee.surname}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              required
              value={assignmentStatus}
              onChange={e => setFormData({ ...formData, assignmentStatus: e.target.value })}
              placeholder="Seleccionar Status"
            >
              <option value="POSTULADO">Postulado</option>
              <option value="ASIGNADO">Asignado</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Rol</FormLabel>
            <Select
              required
              value={role}
              onChange={e => setFormData({ ...formData, role: e.target.value })}
              placeholder="Seleccionar role"
            >
              <option value="SUPERVISOR">Supervisor</option>
              <option value="ASISTENTE">Asistente</option>
              <option value="FOTOGRAFO">Fotografo</option>
              <option value="COMPARTIDO">Compartidos</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Observación</FormLabel>
            <Textarea
              required
              value={observation}
              onChange={e => setFormData({ ...formData, observation: e.target.value })}
              placeholder="Ingresar observación"
              rows={3}
            />
          </FormControl>
          <Button colorScheme="teal" onClick={handleAssign}>
            Asignar
          </Button>
        </VStack>
        <Heading as="h2" size="lg" mt={8}>
          Asignaciones
        </Heading>
        <VStack spacing={4} align="start" w="full">
          <VStack spacing={3} align="start" w="full">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Nombre</Th>
                    <Th>Alias</Th>
                    <Th>Status</Th>
                    <Th>Rol</Th>
                    <Th>Observación</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assignments.map(assignment =>
                    assignment?.assignment_status == 'POSTULADO' ? (
                      <Tr>
                        <Td>
                          {assignment?.employee?.name} {assignment?.employee?.surname}
                        </Td>
                        <Td  fontWeight="bold" >{assignment?.employee?.employee_alias || '-'}</Td>
                        <Td>{assignment?.assignment_status}</Td>
                        <Td> {assignment?.role}</Td>
                        <Td>{assignment?.observation}</Td>
                      </Tr>
                    ) : null,
                  )}
                </Tbody>
              </Table>
            </TableContainer>

          </VStack>
        </VStack>
      </VStack>
    </Container>
  );
}

export default Assignment;
