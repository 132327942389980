// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";
import Billing from "views/Dashboard/Billing";
import Logistics from "views/Dashboard/Logistics";
import Salon from "views/Dashboard/Salon";
import Client from "views/Dashboard/Clients";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import Employee from "views/Dashboard/Employee";
import Event from "views/Dashboard/Events";
import DataGrid from "views/Dashboard/Datagrid";
import EmployeeDataGrid from "views/Dashboard/EmployeeDatagrid"
import AssignDataGrid from "views/Dashboard/AssignDataGrid"
import EmployeePayementAssignDataGrid from "views/Dashboard/EmployeeAssignPayementDataGrid"
import Project from "views/Dashboard/Projects";
import Bill from "views/Dashboard/Bill";
import Payment from "views/Dashboard/Payment";
import Assignment from "views/Dashboard/Assigment";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import Test from "views/Test/Test.js";
import Logistic from "views/Dashboard/Logistic";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  // If id_query is true a component_id should be provided, in order to query /endpoint/{id}
  {
    path: "/tables",
    name: "Tables",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: <Tables />,
    layout: "",
  },
  {
    path: "/create/event",
    name: "Crear Evento",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Project post={true} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/create/salon",
    name: "Salon",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Salon post={true} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/create/client",
    name: "Cliente",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Client post={true} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/edit/event/:id",
    name: "Eventos",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Project post={false} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/employee",
    name: "Lista Empleados",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <Employee />,      //Catch All component, Usually tablec
    layout: "",
  },
  {
    path: "/salon",
    name: "Salon",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <Salon post={true} />,      //Catch All component, Usually tablec
    layout: "",
  },
  {
    path: "/create/assignment/:id",
    name: "Asignar Eventos",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <Assignment />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/datagrid",
    name: "Data Grid",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <DataGrid />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/employee_datagrid",
    name: "Data Grid Empleados",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <EmployeeDataGrid />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/payment_table/:id",
    name: "Data Grid Pago Asignaciones",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <EmployeePayementAssignDataGrid />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/assign_table/:id",
    name: "Data Grid Assignaciones",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <AssignDataGrid />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/payment/:id",
    // path: "/payment",
    name: "Pago a Empleados",
    rtlName: "",
    icon: <PersonIcon color="inherit" />,
    component: <Payment />,      //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/edit/employee/:id",
    name: "Editar Empleados",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Employee />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/create/billing/:id",
    name: "Crear Billing",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Bill post={true} />,         //Catch All component, Usually table
    layout: "",
  },
  // TODO : in case of editing and no logistics change the endpoint to create
  {
    path: "/create/logistic/:id",
    name: "Crear Logistics",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Logistic post={true} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/edit/logistic/:id",
    name: "Editar Logistics",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Logistic />,         //Catch All component, Usually table
    layout: "",
  },
  {
    path: "/edit/billing/:id",
    name: "Editar Billing",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: <Bill post={false} />,         //Catch All component, Usually table
    layout: "",
  },
  {
    name: "Usuario",
    category: "account",
    rtlName: "",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Perfil",
        rtlName: "",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: <Profile />,
        layout: "",
      },
    ],
  },
];
export default dashRoutes;
