import RequireAuth from "components/RequireAuth.js";
import Unauthorized from "components/Unauthorized.js";


import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";


import { Routes, Route } from 'react-router-dom';


const ROLES = {
    'Customer': 'customer',
    'Employee': 'employee',
    'Admin': 'Admin'
  }
  
function App() {

    return (
      <Routes>
          <Route path="signin" element={<AuthLayout />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          {/* we want to protect these routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Employee]} />}>
            <Route path="/*" element={<AdminLayout />} />
          </Route>

          {/* catch all */}

      </Routes>
    );
  }
  
  export default App;