// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar_default from "assets/img/avatars/avatar_default.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import UserInformation from "./components/UserInformation";
import Projects from "./components/Projects";
import useAuth from 'hooks/useAuth';

function Profile() {
  const { auth } = useAuth();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar_default}
        name={auth.username}
        email={String(auth?.roles)}
        tabs={[
          {
            name: "General",
            icon: <FaCube w='100%' h='100%' />,
          }
        ]}
      />
      <UserInformation />
    </Flex>
  );
}

export default Profile;
