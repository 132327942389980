import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDMZiqFnvrVXI51pskucG9eIiGlaJ9Wo6Y",
  authDomain: "babooth.firebaseapp.com",
  projectId: "babooth",
  storageBucket: "babooth.appspot.com",
  messagingSenderId: "307923018751",
  appId: "1:307923018751:web:04ec17582b343e62593dca",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const firestore = getFirestore(app);
const storage_ref = ref(storage)

export { storage, firestore };
