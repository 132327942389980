// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import routes from "routes.js";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const settingsRef = React.useRef();

  // Logout button logic
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("auth");
    setAuth(null);
    navigate("/signin");
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Button
        me={{ sm: "2px", md: "16px" }}
        as={RouterLink}
        colorScheme="red"
        variant="outline"
        to="/create/salon"
        colorScheme="teal"
      >
        + Salon
      </Button>
      <Button
        me={{ sm: "2px", md: "16px" }}
        colorScheme="red"
        variant="outline"
        as={RouterLink}
        to="/create/client"
        colorScheme="teal"
      >
        + Cliente
      </Button>

      <IconButton
        as={NavLink}
        to="/profile"
        icon={<ProfileIcon />}
        me={{ sm: "2px", md: "16px" }}
        colorScheme="teal"
        variant="outline"
      />
      <Button
        // ms="0px"
        // px="0px"
        me={{ sm: "2px", md: "16px" }}
        colorScheme="red"
        variant="outline"
        onClick={handleLogOut}
      >
        <Text display={{ sm: "none", md: "flex", color: "tomato" }}>
          Sign Out
        </Text>
      </Button>

      <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="40px"
        h="40px"
      />
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
