// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import useAuth from "hooks/useAuth";
import CrudForm from "components/Forms/GenericCRUDForm.js";

const endpoint = "/employee";

const EmployeeEchema = Yup.object().shape({
  id: Yup.number().meta({ readOnly: true }),
  name: Yup.string().required(),
  surname: Yup.string().required(),
  birthday: Yup.date().required().meta({ dateOnly: true }),
  cuit: Yup.string().nullable(),
  cellphone_no: Yup.string().nullable(),
  has_car: Yup.boolean(),
  license_number: Yup.string().nullable(),
  cbu: Yup.string().nullable(),
  alias: Yup.string()
    .max(20, "Debe ser menor o igual a 20 caracteres")
    .nullable(),
});

const translator = {
  id: "Id Empleado",
  name: "Nombre",
  surname: "Apellido",
  birthday: "Fecha de Nacimiento",
  cuit: "CUIT Fiscal",
  cellphone_no: "Número de Celular",
  has_car: "¿Tiene Automóvil?",
  license_number: "Número de Patente",
  cbu: "CBU Bancario",
  alias: "Alias Bancario",
};

function Employee() {
  const { id } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  return (
    <Flex direction="column">
      <CrudForm
        endpoint={endpoint}
        validationSchema={EmployeeEchema}
        id={id}
        translator={translator}
        invalid_keys={[]}
      />
    </Flex>
  );
}
export default Employee;
