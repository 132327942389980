//  Translates the keys of an string to Spanish
//  @param {String} key - The object to translate

export const translateKey = (key) => {
    // Translate the key to Spanish if keys are in English (default)
    let translatedKey = '';
    switch (key) {
      case 'name':
        translatedKey = 'Nombre';
        break;
      case 'surname':
        translatedKey = 'Apellido';
        break;
      case 'cuit':
        translatedKey = 'CUIT';
        break;
      case 'cellphone_no':
        translatedKey = 'Telefono Celular';
        break;
      case 'has_car':
        translatedKey = 'Tiene Auto';
        break;
      case 'employee_id':
        translatedKey = 'ID Empleado';
        break;
      case 'license_number':
        translatedKey = 'Numero de Licencia';
        break;
      case 'cbu':
        translatedKey = 'CBU Bancario';
        break;
      case 'alias':
        translatedKey = 'Alias Bancario';
        break;
      case 'birthday':
        translatedKey = 'Fecha Nacimiento';
        break;
      default:
        translatedKey = key; // Use the original key if there's no translation available
      }

    // Capitalize the first letter of the translated key
    const capitalizedKey =
      translatedKey.charAt(0).toUpperCase() + translatedKey.slice(1);
  
    return capitalizedKey;
  };

