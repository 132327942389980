import { React, useRef, useState, useEffect, useContext } from 'react';
import { useFormik } from "formik";
import axios from 'api/axios';
import { api_get_token, api_get_roles } from 'api/apiModels';

import useAuth from 'hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

// API PATH URL 
const debug = true;


// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";

function SignIn() {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [role, setRole] = useState('');

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])



  const handleSubmit = async (e) => {

    e.preventDefault();
    try {

      setUser(formik.values.email);
      setPwd(formik.values.password);

      const accessToken = await api_get_token(formik.values.email, formik.values.password);

      const roles = await api_get_roles(accessToken);
      
      const auth_data = { "username": formik.values.email, "password": formik.values.password, "roles": roles, "accessToken": accessToken };

      setRole(roles);
      setAuth(auth_data);
      setSuccess(true);

      debug ? console.log('roles data:' + JSON.stringify(roles)) : null;
      debug ? console.log('auth data:' + JSON.stringify(auth_data)) : null;

      //Redirect if not from signin
      if (from !== "/signin") {
        navigate(from, { replace: true });
      } else {
        navigate("/", { replace: true });
      }


    }
    catch (err) {
      setErrMsg(err);
      setSuccess(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: (values) => {
    },
  });
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Bienvenido!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Introduzca su correo electrónico y contraseña para iniciar sesión
            </Text>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  id="email"
                  name="email"
                  type="email"
                  variant="filled"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Contraseña
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  id="password"
                  name="password"
                  type="password"
                  variant="filled"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <Button
                  fontSize="10px"
                  type="submit"
                  bg="teal.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  SIGN IN
                </Button>
              </FormControl>
              {errMsg && (
                <Alert status='error'>
                  <AlertIcon />
                  <AlertTitle>{errMsg}</AlertTitle>
                </Alert>
              )}
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            ></Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
