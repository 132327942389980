// Chakra imports
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Select,
  Flex,
  Box,
  Grid,
  useColorModeValue,
  Button,
  Spacer
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import AgGridTable from "components/Grid/Grid.js";
import { FaExpand } from "react-icons/fa";
import { subMonths } from 'date-fns';

function DataGrid() {
  const { id } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const agGridTableRef = useRef(null);
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const [view, setView] = useState("supervision_eventos");

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  // Add this function to the DataGrid component
  const handleFilterButtonClick = (type) => {
    const gridApi = agGridTableRef.current.api;
    const currentDate = new Date();
    let dateFrom;
    let dateTo;

    const FirstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

    switch (type) {
      case "lastMonth":
        dateFrom = subMonths(FirstDayOfMonth, 1);
        dateTo = FirstDayOfMonth;
        break;
      case "currentMonth":
        dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case "nextMonth":
        dateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        dateTo = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
        break;
      case "year":
        dateFrom = new Date(currentDate.getFullYear(), 0, 1);
        dateTo = new Date(currentDate.getFullYear(), 11, 31);
        break;
      case "everything":
        dateFrom = null;
        dateTo = null;
        break;
      default:
        break;
    }


    gridApi.getFilterInstance("start_date").setModel({
      type: "inRange",
      dateFrom: dateFrom ? dateFrom.toISOString() : null,
      dateTo: dateTo ? dateTo.toISOString() : null,
    });

    gridApi.onFilterChanged();
  };

  const handleFullScreen = () => {
    const table = document.querySelector(".ag-theme-alpine .ag-root-wrapper");
    if (table.requestFullscreen) {
      table.requestFullscreen();
    } else if (table.webkitRequestFullscreen) {
      table.webkitRequestFullscreen();
    } else if (table.msRequestFullscreen) {
      table.msRequestFullscreen();
    }
  };





  return (
    <Flex pt={{ base: "120px", md: "75px" }} direction="column">
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Button as={RouterLink} to="/create/event" colorScheme="teal">+ Agregar Evento</Button>
        <Button as={RouterLink} to="/payment" colorScheme="green">+ Pagos</Button>
        <Button onClick={() => handleFilterButtonClick("lastMonth")} variant="outline">Mes Anterior</Button>
        <Button onClick={() => handleFilterButtonClick("currentMonth")} variant="outline">Mes Actual</Button>
        <Button onClick={() => handleFilterButtonClick("nextMonth")} variant="outline">Mes Próximo </Button>
        <Button onClick={() => handleFilterButtonClick("year")} variant="outline">Año</Button>
        <Button onClick={() => handleFilterButtonClick("everything")} variant="outline">Todo</Button>

        <Spacer />
        <Box as="label" fontWeight="bold" mr="2">
          Seleccionar Vistas:
        </Box>
        <Select value={view} onChange={handleViewChange} width="300px" mr="4">
          <option value="supervision_eventos">Supervisión de Eventos</option>
          <option value="panel_facturacion">Panel de Facturación</option>
          <option value="all">Todo</option>
        </Select>
        <Button
          onClick={handleFullScreen}
          leftIcon={<FaExpand />}
          variant="outline"
        ></Button>
      </div>
      <Box py={4}> {/* Added Box component with vertical padding */}
        <AgGridTable ref={agGridTableRef} view={view} />
      </Box>
    </Flex>
  );
}
export default DataGrid;
