// Chakra imports
import { Flex, Grid, useColorModeValue, Input } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'api/axios';
import useAuth from 'hooks/useAuth';
import CrudForm from 'components/Forms/GenericCRUDForm.js';
import { SalonSchema, SalonSchemaTranslation } from 'library/yupModels/yupmodels.js';
import {
  Divider,
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

const endpoint = '/salon';

function SalonList({ searchQuery, page, setPage }) {
  const [salons, setSalons] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const { auth } = useAuth();
  const accessToken = auth.accessToken;

  const fetchSalons = async () => {
    try {
      const response = await axios.get('/salon', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
        },
      });

      // Filter the salons based on the search query
      const filteredSalons = response?.data?.data.filter(
        salon =>
          salon.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          salon.address.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      // Calculate the total number of pages based on the filtered results
      const pageSize = 10; // Set the number of salons to display per page
      const totalSalons = filteredSalons.length;
      setTotalPages(Math.ceil(totalSalons / pageSize));

      // Apply pagination to the filtered results
      const startIndex = (page - 1) * pageSize;
      const paginatedSalons = filteredSalons.slice(startIndex, startIndex + pageSize);

      return paginatedSalons;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  useEffect(() => {
    fetchSalons().then(salon => {
      setSalons(salon);
    });
  }, [searchQuery, page]);

  if (salons.length === 0) {
    return <Text>No salon found.</Text>;
  }

  return (
    <VStack spacing={3} align="start" w="full">
      {/* Display SalonListpaginated and filtered salons */}
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Dirección</Th>
              <Th>Nombre</Th>
              <Th>Razón Social</Th>
              <Th>CUIL</Th>
            </Tr>
          </Thead>
          <Tbody>
            {salons.map(salon => (
              <Tr>
                <Td>{salon?.address || '-'}</Td>
                <Td>{salon?.name}</Td>
                <Td>{salon?.social_reason}</Td>
                <Td>{salon?.cuil}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box mt={4}>
        {page > 1 && (
          <Button onClick={() => setPage(page - 1)} colorScheme="blue" mr={2}>
            Previous
          </Button>
        )}
        {page < totalPages && (
          <Button onClick={() => setPage(page + 1)} colorScheme="blue">
            Next
          </Button>
        )}
      </Box>
    </VStack>
  );
}

function Salon({ post }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const textColor = useColorModeValue('gray.700', 'white');
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)',
  );

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        {/* Search Box */}
        <CrudForm
          title={'Salon'}
          endpoint={endpoint}
          validationSchema={SalonSchema}
          id={id}
          post={post}
          translator={SalonSchemaTranslation}
          invalid_keys={['id']}
          reload={true}
        />
      </Flex>
      <div style={{ padding: '20px 0' }}>
        {' '}
        <Divider />{' '}
      </div>
      <Flex direction="column">
        <div style={{ padding: '20px 0' }}>
          <FormControl>
            <Input
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="🔍 Buscar salon por nombre o dirección..."
            />
          </FormControl>
        </div>
        {/* Pass searchQuery and page to SalonList */}
        <SalonList searchQuery={searchQuery} page={page} setPage={setPage} />
      </Flex>
    </>
  );
}

export default Salon;
