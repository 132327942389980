// Chakra imports
import { Button,Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import useAuth from 'hooks/useAuth';
import CrudForm from "components/Forms/GenericCRUDForm.js";

const endpoint = '/event';
import {Eventschema, EventschemaTranslations} from 'library/yupModels/yupmodels.js'


function Event({ post }) {
  const { id } = useParams();

  const textColor = useColorModeValue('gray.700', 'white');
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
  );

  let rurl = null;
  if (post) {
    rurl = `/create/billing/${id}`;
  } else {
    rurl = `/edit/billing/${id}`;
  }

  const rbutton = {
    url: rurl, 
    name: 'Billing' 
  } 

  return (
    <>
      <CrudForm
        title={'Event'}
        endpoint={endpoint}
        validationSchema={Eventschema}
        id={id}
        translator={EventschemaTranslations}
        post={post}
        redirectButton = {rbutton}
      />
    </>
  );
}

export default Event;
