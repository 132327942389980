// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import useAuth from 'hooks/useAuth';
import CrudForm from "components/Forms/GenericCRUDForm.js";
import {Eventschema, EventschemaTranslations} from 'library/yupModels/yupmodels.js'
const endpoint = '/event';


const translator = { };

function Project({post}) {
    const { id } = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
      "hsla(0,0%,100%,.8)",
      "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    let redirectButton;
    if(post){
      redirectButton = {'url': `/create/billing`, 'name': 'Billing'};
    } else {
      redirectButton = {'url': `/edit/billing`, 'name': 'Billing'};
    }


    return(        
        <CrudForm
        title={'Evento'}
        endpoint={endpoint}
        validationSchema={Eventschema}
        id = {id}
        translator={EventschemaTranslations}
        post={post}
        invalid_keys={['id','billing','plot','created_at']}
        redirectButton={redirectButton}
        />
    )
}
export default Project;
