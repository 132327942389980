import * as Yup from 'yup';

function transform_date(currentValue, originalValue) {
  console.log(currentValue);
  console.log(originalValue);
  if (originalValue && typeof originalValue === 'string') {
    const [hours, minutes] = originalValue.split(':');
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date;
  }
  return currentValue;
}

export const EVENT_TYPE_CHOICES = [
  'Bar Mitzvah',
  'Casamiento',
  'Baby Shower',
  'Corporativo',
  'Cumpleaños',
  'Fiesta de Quince',
  'PDV',
  'Boliches',
  'Varios',
  '',
];

export const ORGANIZER_CHOICES = ['FDV', 'LF', 'PIO', 'MGDN', 'LSF'];

export const SERVICE_TYPE_CHOICES = [
  'Alquiler equipo',
  'Autobooth',
  'Autobooth + Video + Edicion',
  'Autobooth Karaoke',
  'Barrel Booth',
  'Behind the scenes',
  'Bullet Time + Light Painting',
  'Bullet Time',
  'Cabina Foto + Karaoke',
  'Cabina Foto + Karaoke + Edicion',
  'Cabina Foto + Video',
  'Cabina Foto + Video + Edicion',
  'Cabina Karaoke',
  'Cabina PBU Mundial',
  'Cabina PBU Mundial + impresión',
  'Cabina Photobooth',
  'Spin Cam',
  'Drone',
  'Classic Video',
  'Flip Book',
  'Fly & Print',
  'Fotografia Convencional',
  'Fotografo',
  'Gif Booth',
  'Inflable Foto + Karaoke',
  'Inflable Foto + Karaoke + Edicion',
  'Inflable Foto + Video + Edicion',
  'Inflable Interactive Booth',
  'Inflable Karaoke',
  'Inflable PBU Mundial',
  'Inflable PBU Mundial + impresión',
  'Inflable Photobooth',
  'Inflable Videobooth',
  'Inside the Party',
  'Jump Cut',
  'Karaoke',
  'Kiosco de Impresiones',
  'Light Painting Inflable',
  'Mirror Booth',
  'Mirror Booth Foto + Video + Edicion',
  'Mini Bullet',
  'Monster Totem',
  'Motobooth',
  'Props Unicamente',
  'Roaming Booth',
  'Share & Print',
  'Snap Shot',
  'Snap Shot Design',
  'Snap Shot Plain',
  'Snap Shot Wide',
  'Totem Foto + Boomerang',
  'Totem Foto + Gif',
  'Totem Foto + Karaoke',
  'Totem Foto + Karaoke + Edicion',
  'Totem Foto + Video',
  'Totem Foto + Video + Edicion',
  'Totem Gif Booth',
  'Totem Interactive Booth',
  'Totem Karaoke',
  'Totem PBU Mundial',
  'Totem PBU Mundial + impresion',
  'Totem Photobooth',
  'Totem Videobooth',
  'Totem Videobooth con edicion',
  'Trend Booth',
  'Varios',
  'Virtual Booth',
  'Photobus',
  'Cabina Videobooth',
  'Windbooth',
  '',
];

const STRIP_CHOICES = ['Pendiente', 'SUBIDO AL DRIVE'];

export const PrintingSchema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  printing: Yup.boolean().default(false),
});

export const EmployeeSchema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  name: Yup.string().max(51),
  surname: Yup.string().max(50),
  cuit: Yup.string().max(12),
  birthday: Yup.date().nullable(),
  cellphone_no: Yup.string().max(20).nullable(),
  has_car: Yup.boolean().default(false),
  license_number: Yup.string().max(7).nullable(),
  cbu: Yup.string().max(20).nullable(),
  alias: Yup.string().max(20).nullable(),
});

export const EmployeeAssignmentSchema = Yup.object().shape({
  assignment_status: Yup.string().max(30).oneOf(['POSTULADO', 'ASIGNADO']).default('POSTULADO').nullable(),
  role: Yup.string().max(30).oneOf(['SUPERVISOR', 'ASISTENTE']).nullable(),
  observation: Yup.string().max(50).default('').nullable(),
});

export const EmployeeInvoiceSchema = Yup.object().shape({
  total_rate: Yup.number(),
  payed_rate: Yup.number(),
});

export const BillingSchema = Yup.object().shape({
  client_name: Yup.string().max(50).nullable(),
  service_type: Yup.string()
    .max(50)
    .oneOf(SERVICE_TYPE_CHOICES)
    .meta({
      choices: SERVICE_TYPE_CHOICES,
    })
    .nullable(),
  event_type: Yup.string()
    .max(50)
    .oneOf(EVENT_TYPE_CHOICES)
    .meta({
      choices: EVENT_TYPE_CHOICES,
    })
    .nullable(),
  user_price: Yup.number().nullable(),
  net_price: Yup.number().nullable(),
  invoice: Yup.boolean().default(false),
  invoice_issue_date: Yup.date().meta({ dateOnly: true }).nullable(),
  invoice_number: Yup.string().max(50).nullable(),
  iva: Yup.number().nullable(),
  charged_amount: Yup.number().nullable(),
  net_balance: Yup.number().nullable(),
  iva_balance: Yup.number().nullable(),
  payment_method: Yup.string().max(50).nullable(),
  payment_date: Yup.date().meta({ dateOnly: true }).nullable(),
  payment_method_type: Yup.string().max(50).nullable(),
  cuit: Yup.string().max(20).nullable(),
  observations: Yup.string().max(200).nullable(),
});

export const Eventschema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  created_at: Yup.date().nullable(),
  event_name: Yup.string().max(100),
  _date: Yup.date().meta({ dateOnly: true }),
  organization: Yup.string()
    .max(100)
    .meta({
      choices: ORGANIZER_CHOICES,
    })
    .nullable(), // Responsible for event
  client_name: Yup.string().max(100),
  contact_phone: Yup.string().max(100).nullable(),
  address: Yup.string().max(1000).meta({ isMap: true, post: false }),
  strip_status: Yup.string().max(30).oneOf(STRIP_CHOICES).nullable().meta({
    choices: STRIP_CHOICES,
  }),
  remarks: Yup.string().max(2000).nullable(),
  bulk_status: Yup.string().max(30).nullable(),
  // start_date: Yup.date().transform(transform_date).default(function (obj) { return obj?._date ? obj._date : null; }),
  start_date: Yup.date().nullable().typeError('Invalid date'),
  // end_date: Yup.date().transform(transform_date).default(function (obj) { return obj?._date ? obj._date : null; }),
  end_date: Yup.date().nullable().typeError('Invalid Date!'),
  insurance: Yup.string().max(2000).nullable(),
  structure: Yup.string().max(2000).nullable(),
  printing: Yup.boolean(),
  logistic: Yup.boolean(),
});

export const LogisticsSchema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  assemble_start_date: Yup.date(),
  assemble_end_date: Yup.date(),
  dissasemble_start_date: Yup.date(),
  dissasemble_end_date: Yup.date(),
  observaciones: Yup.string().max(95).nullable(),
  lateral_panes: Yup.string().max(95).nullable(),
  internal_mdf: Yup.string().max(95).nullable(),
  back_mdf: Yup.string().max(95).nullable(),
  structure: Yup.string().max(95).nullable(),
  hired_service: Yup.string().max(200).nullable(),
  file: Yup.object().shape({
    file: Yup.mixed()
      // .required("You need to provide a file")
      // .test("fileSize", "The file is too large", (value) => {
      //   return value && value[0].size <= 2000000;
      // })
      .test('type', 'Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc', value => {
        // TODO SET The field file in the formik values prop in order to pass validation!!
        console.log({ value });
        return true;
        // return value && (
        //   value[0].type === "image/jpeg" ||
        //   value[0].type === "image/bmp" ||
        //   value[0].type === "image/png" ||
        //   value[0].type === 'application/pdf' ||
        //   value[0].type === "application/msword"
        // );
      }),
  }),
});

export const SalonSchema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  address: Yup.string().max(95).nullable(),
  name: Yup.string().max(95).nullable(),
  social_reason: Yup.string().max(95).nullable(),
  cuil: Yup.string().max(64).nullable(),
});

export const clientSchema = Yup.object().shape({
  id: Yup.number().integer().meta({ readOnly: true }),
  contact_name: Yup.string().max(100).nullable(),
  legal_name: Yup.string().max(50).nullable(),
  cuit: Yup.string().max(100).nullable(),
  email: Yup.string().max(100).nullable(),
  cellphone: Yup.string().max(100).nullable(),
  invoice: Yup.string().max(100).nullable(),
});

// TODO: Add logistic button
export const EventschemaTranslations = {
  id: 'ID',
  created_at: 'Fecha de Creación',
  event_name: 'Nombre del Evento',
  printing: 'Ploteo',
  printing_description: 'Descripción del Ploteo',
  company_name: 'Nombre de la Empresa',
  organization: 'Organizador',
  client_name: 'Nombre Cliente / Fantasía',
  contact_phone: 'Teléfono de Contacto',
  _date: 'Fecha', // TODO: show only date and make font of value larger
  address: 'Dirección',
  strip_status: 'Estado de la Tira',
  remarks: 'Observaciones',
  bulk_status: 'Estado de Bulto',
  insurance: 'Seguro',
  structure: 'Estructura',
  hired_service: 'Servicio Contratado',
  start_date: 'Hora Inicio',
  end_date: 'Hora Finalización',
  logistic: 'Logística', //TODO: Add logistic button to DB
};

export const BillingSchemaTranslation = {
  id: 'ID',
  client_name: 'Cliente',
  service_type: 'Servicio',
  event_type: 'Tipo de evento',
  user_price: 'Precio al usuario',
  net_price: 'Precio Neto',
  invoice: 'Factura',
  invoice_issue_date: 'Fecha de Emisión de la Factura',
  invoice_number: 'Número de Factura',
  iva: 'IVA',
  charged_amount: 'Cobrado',
  net_balance: 'Saldo Neto',
  iva_balance: 'Saldo IVA',
  payment_method: 'Forma de pago',
  payment_date: 'Fecha de cobro',
  payment_method_type: 'Vía de cobro',
  cuit: 'CUIT',
  observations: 'Observaciones',
};

export const EmployeeSchemaTranslation = {
  id: 'ID',
  employee_alias: 'Alias Empleado',
  assigned_events: 'Eventos Asignados',
  name: 'Nombre',
  email: 'Email',
  surname: 'Apellido',
  cuit: 'CUIT',
  birthday: 'Cumpleaños',
  cellphone_no: 'Número de Teléfono',
  has_car: 'Auto',
  license_number: 'Patente',
  cbu: 'CBU',
  alias: 'Alias Bancario',
  user: 'Usuario',
};

export const SalonSchemaTranslation = {
  id: 'ID',
  address: 'Dirección',
  name: 'Nombre',
  social_reason: 'Razón Social',
  cuil: 'CUIL',
};

export const LogisticsSchemaTranslation = {
  id: 'ID',
  event_logistics: 'Evento',
  assemble_start_date: 'Fecha inicio armado',
  assemble_end_date: 'Fecha fin armado',
  dissasemble_start_date: 'Fecha inicio desarmado',
  dissasemble_end_date: 'Fecha fin desarmado',
  observaciones: 'Observaciones',
  lateral_panes: 'Paneles Laterales',
  internal_mdf: 'Placas MDF Internas',
  hired_service: 'Servicio Contratado',
  file: 'Archivos adicionales',
  back_mdf: 'Placas MDF Traseras',
  structure: 'Estructura',
};

export const EmployeeAssignmentSchemaTranslation = {
  events: 'Evento',
  employee: 'Empleado',
  name: 'Nombre',
  assignment_status: 'Estado Asignacion',
  total_rate: 'Monto Total',
  payed_rate: 'Monto Pagado',
  payment_status: 'Estado Pago',
  role: 'Rol',
  observation: 'Observación',
};

export const clientSchemaTranslation = {
  id: 'ID',
  contact_name: 'Nombre Fantasía',
  legal_name: 'Razón Social',
  cuit: 'CUIT',
  email: 'Email',
  cellphone: 'Celular',
  invoice: 'Tipo Factura',
};
