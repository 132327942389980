import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import axios from "api/axios.js";
import useAuth from "hooks/useAuth";
import { Button, Flex, Spacer } from "@chakra-ui/react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import numeral from 'numeral';

const endpoint = "/render";

const DisplayDate = params => {
  const date = new Date(params.value);

  // Define the options for formatting the date in Spanish
  const options = {
    day: 'numeric',
    month: 'long',
  };

  // Format the date using the Spanish locale
  const formattedDate = date.toLocaleString("es-ES", options);

  // You may want to return the date within a React fragment or another JSX element
  return formattedDate;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const displayMoney = v => {
  return formatter.format(v)
}

const editEventButton = {
  headerName: "Edit",

  cellRenderer: function (params) {
    const id = params.data.id;
    return (
      <button onClick={() => (window.location.href = `/event/${id}`)}>
        Edit
      </button>
    );
  }
};

const AssignedEmployeesCellRenderer = ({ value }) => {
  if (!value || !Array.isArray(value) || value.length === 0) {
    return null;
  }

  // Filter out null employee_alias and create a Set to remove duplicates
  const uniqueEmployeeAliases = new Set(
    value
      .map(emp => emp.employee_alias)
      .filter(alias => alias !== null)
  );

  // Join the unique employee aliases with a comma
  const collapsedAliases = Array.from(uniqueEmployeeAliases).join(', ');

  return <span>{collapsedAliases}</span>;
};

const DateFilterDisplay = {
  suppressMultiSort: true,
  cellRenderer: DisplayDate,
  filter: "agDateColumnFilter",
  filterParams: {
    filterOptions: ["inRange"],
    comparator: function (filterLocalDate, cellValue) {
      filterLocalDate = new Date(filterLocalDate);
      // you need to handle the Z appended to the end of the cellValue string,
      // otherwise you will get the incorrect date you're expecting
      // Take out ms in date
      cellValue = new Date(cellValue.slice(0, -1).split(".")[0]);
      let filterBy = filterLocalDate.getTime();
      let filterMe = cellValue.getTime();

      console.log(filterBy, filterMe);
      if (filterBy === filterMe) {
        return 0;
      }

      if (filterMe < filterBy) {
        return -1;
      }

      if (filterMe > filterBy) {
        return 1;
      }
    },
    minValidYear: 2020,
    maxValidYear: 2030,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    browserDatePicker: true,
    type: "date",
    includeBlanksInLessThan: false,
    includeBlanksInGreaterThan: false,
    includeBlanksInRange: false
  }
};


const getQuickFilterText = params => {
  return params.value.name;
};


const columnSupervisionEventos = [
  // { field: "id", headerName: "ID", filter: true , width: 100},
  {
    headerName: "Editar",
    cellRenderer: function (params) {
      const id = params.data.id;
      return (
        <button onClick={() => (window.location.href = `/edit/event/${id}`)}>
          <span>&#9998;</span>
        </button>
      );
    },
    width: 75
  },
  {
    field: "event_name",
    headerName: "Nombre del Evento",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    ...DateFilterDisplay,
    field: "_date",
    headerName: "Fecha"
  },

  {
    field: "service_type",
    headerName: "Tipo de Servicio",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },

  {
    field: "printing",
    headerName: "Ploteo",
    cellRenderer: function (params) {
      return params.value ? "✅" : "❌";
    }
  },

  {
    field: "insurance",
    headerName: "Seguro",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    field: "strip_status",
    headerName: "Estado de Strip",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    field: "bulk_status",
    headerName: "Estado del Bulto",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    field: "start_date",
    headerName: "Inicio",
    ...DateFilterDisplay
  },
  {
    field: "end_date",
    headerName: "Finalización",
    ...DateFilterDisplay
  },
  {
    field: "address",
    headerName: "Dirección",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    field: "contact_phone",
    headerName: "Teléfono de Contacto",
    filter: true,
    getQuickFilterText: getQuickFilterText
  },
  {
    field: "assigned_employees",
    headerName: "Empleados Asignados",
    cellRendererFramework: AssignedEmployeesCellRenderer
  }
];

const columnPanelFacturacion = [
  {
    headerName: "Editar",
    cellRenderer: function (params) {
      return (
        <button onClick={() => (window.location.href = `/edit/billing/${params.data.billing.id}`)}>
          <span>&#9998;</span>
        </button>
      );
    },
    width: 75
  },
  {
    field: "event_name",
    headerName: "Evento",
  },
  {
    field: "billing.client_name",
    headerName: "Cliente",
  },
  {
    field: "billing.billed_event.address",
    headerName: "Lugar",
  },
  {
    ...DateFilterDisplay,
    field: "_date",
    headerName: "Fecha"
  },
  {
    field: "billing.billed_event.organization",
    headerName: "Org",
  },
  {
    field: "billing.event_type",
    headerName: "Tipo de evento",
  },
  {
    field: "billing.service_type",
    headerName: "Servicio",
  },
  {
    field: "printing",
    headerName: "Ploteo",
    cellRenderer: function(params) {
      return params.value?.printing ? "✅" : "❌";
    }
  },
  {
    field: "billing.additional",
    headerName: "Adicional",
  },
  {
    field: "billing.user_price",
    headerName: "Precio al usuario",
    cellRenderer: function (params) {
      return displayMoney(params.value);
    }
  },
  {
    field: "billing.net_price",
    headerName: "Precio net",
    cellRenderer: function (params) {
      return displayMoney(params.value);
    }
  },
  {
    field: "billing.charged_amount",
    headerName: "Cobrado",
    valueGetter: params => {
      return displayMoney(parseFloat(params.data.billing.net_price) - parseFloat(params.data.billing.net_balance));
    },
    valueFormatter: params => `$${numeral(params.value).format('0,0.0')}`,
  },
  {
    field: "billing.iva_balance",
    headerName: "Saldo IVA",
    cellRenderer: function (params) {
      return displayMoney(params.value);
    }
  },
  {
    field: "billing.payment_method",
    headerName: "Forma de pago",
  },
  {
    ...DateFilterDisplay,
    field: "billing.payment_date",
    headerName: "Fecha de cobro",
  },
  {
    field: "billing.payment_method_type",
    headerName: "Vía de cobro",
  },
  {
    field: "billing.observations",
    headerName: "Observaciones",
  },
];


function unnestKey(dataArray, key) {
  return dataArray.map(item => {
    let nestedData = Array.isArray(item[key]) ? item[key][0] : item[key];
    return {
      ...item,
      ...nestedData,
      [key]: undefined,  // to remove the original nested object
    };
  });
}


const AgGridTable = forwardRef(({ view }, ref) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);

  const [rowData, setRowData] = useState([]);
  const { auth } = useAuth();

  const accessToken = auth.accessToken;

  const onGridReady = params => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params?.api?.setFloatingFilter ? params.api.setFloatingFilter(true) : null;
  };


  useImperativeHandle(ref, () => ({
    api: gridApi
  }));

  useEffect(() => {
    let isMounted = true;

    axios
      .get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        if (isMounted) {
          console.log(response.data);
          let rowData = response.data.filter((item) => item.billing !== null);
          console.log(rowData);
          setRowData(rowData);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const columnDefs =
    view === "supervision_eventos"
      ? columnSupervisionEventos
      : view === "panel_facturacion"
      ? columnPanelFacturacion
      : columnDefsAll;


  return (
    <div className="ag-theme-alpine" style={{ height: "800px", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        suppressMenuHide={true}
        treeData={true}
        animateRows={true}
        groupDefaultExpanded={-1}
        autoGroupColumnDef={{ minWidth: 200 }}
        enableSorting={true}
        enableFilter={true}
        onGridReady={onGridReady}
        floatingFilter={true}
        enableCellTextSelection={true}
      />
    </div>
  );
});

export default AgGridTable;
