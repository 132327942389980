import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "api/axios.js";
import useAuth from "hooks/useAuth";
import { Button, Flex, Spacer } from "@chakra-ui/react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";
const endpoint = "/employee_render";

const DisplayDate = (params) => {
  const date = new Date(params.value);

  // Define the options for formatting the date in Spanish
  const options = {
    day: "numeric",
    month: "long",
  };

  // Format the date using the Spanish locale
  const formattedDate = date.toLocaleString("es-ES", options);

  // You may want to return the date within a React fragment or another JSX element
  return formattedDate;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const displayMoney = (v) => {
  return formatter.format(v);
};

const editEventButton = {
  headerName: "Edit",

  cellRenderer: function (params) {
    const id = params.data.id;
    return (
      <button onClick={() => (window.location.href = `/event/${id}`)}>
        Edit
      </button>
    );
  },
};

const AssignedEmployeesCellRenderer = ({ value }) => {
  if (!value || !Array.isArray(value) || value.length === 0) {
    return null;
  }

  // Filter out null employee_alias and create a Set to remove duplicates
  const uniqueEmployeeAliases = new Set(
    value.map((emp) => emp.employee_alias).filter((alias) => alias !== null)
  );

  // Join the unique employee aliases with a comma
  const collapsedAliases = Array.from(uniqueEmployeeAliases).join(", ");

  return <span>{collapsedAliases}</span>;
};

const DateFilterDisplay = {
  suppressMultiSort: true,
  cellRenderer: DisplayDate,
  filter: "agDateColumnFilter",
  filterParams: {
    filterOptions: ["inRange"],
    comparator: function (filterLocalDate, cellValue) {
      filterLocalDate = new Date(filterLocalDate);
      // you need to handle the Z appended to the end of the cellValue string,
      // otherwise you will get the incorrect date you're expecting
      // Take out ms in date
      cellValue = new Date(cellValue.slice(0, -1).split(".")[0]);
      let filterBy = filterLocalDate.getTime();
      let filterMe = cellValue.getTime();

      console.log(filterBy, filterMe);
      if (filterBy === filterMe) {
        return 0;
      }

      if (filterMe < filterBy) {
        return -1;
      }

      if (filterMe > filterBy) {
        return 1;
      }
    },
    minValidYear: 2020,
    maxValidYear: 2030,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    browserDatePicker: true,
    type: "date",
    includeBlanksInLessThan: false,
    includeBlanksInGreaterThan: false,
    includeBlanksInRange: false,
  },
};

const getQuickFilterText = (params) => {
  return params.value.name;
};

const renderPagos = (value) => {
  const id = value.data.id;
  value = `payment_table/${id}`;
  return <Link to={`/${value}`}>&#9998;</Link>;
};
const renderAssign = (value) => {
  const id = value.data.id;
  value = `assign_table/${id}`;
  return <Link to={`/${value}`}>&#9998;</Link>;
};

const columnEmployees = [
  // { field: "id", headerName: "ID", filter: true , width: 100},
  {
    headerName: "Asignaciones",
    cellRenderer: renderAssign,
    width: 100,
  },
  {
    headerName: "Pagos",
    cellRenderer: renderPagos,
    width: 100,
  },
  {
    field: "name",
    headerName: "Nombre",
    filter: true,
    getQuickFilterText: getQuickFilterText,
  },
  {
    field: "surname",
    headerName: "Apellido",
    filter: true,
    getQuickFilterText: getQuickFilterText,
  },
  {
    field: "email",
    headerName: "Email",
    filter: true,
    getQuickFilterText: getQuickFilterText,
    cellRenderer: function (params) {
      return params.value ? params.value : "foo@bar_mail.com";
    },
  },
  {
    field: "cuit",
    headerName: "Cuit",
    filter: true,
    getQuickFilterText: getQuickFilterText,
    cellRenderer: function (params) {
      return params.value
        ? params.value
        : Math.floor(Math.random() * 100000000000);
    },
  },
  {
    field: "employee_alias",
    headerName: "Alias",
    filter: true,
    getQuickFilterText: getQuickFilterText,
  },
  {
    ...DateFilterDisplay,
    field: "birthday",
    headerName: "Fecha Nacimiento",
    cellRenderer: function (params) {
      return params.value ? params.value : "11/10/2023";
    },
  },

  {
    field: "has_car",
    headerName: "Auto",
    cellRenderer: function (params) {
      return params.value ? "✅" : "❌";
    },
  },
];

function unnestKey(dataArray, key) {
  return dataArray.map((item) => {
    let nestedData = Array.isArray(item[key]) ? item[key][0] : item[key];
    return {
      ...item,
      ...nestedData,
      [key]: undefined, // to remove the original nested object
    };
  });
}

const EmployeeGrid = forwardRef(({ view }, ref) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);

  const [rowData, setRowData] = useState([]);
  const { auth } = useAuth();

  const accessToken = auth.accessToken;

  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params?.api?.setFloatingFilter ? params.api.setFloatingFilter(true) : null;
  };

  useImperativeHandle(ref, () => ({
    api: gridApi,
  }));

  useEffect(() => {
    let isMounted = true;

    axios
      .get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${accessToken}`,
        },
      })
      .then((response) => {
        if (isMounted) {
          console.log(response.data);
          let rowData = response.data.filter((item) => item.billing !== null);
          console.log(rowData);
          setRowData(rowData);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const columnDefs = columnEmployees;

  return (
    <div className="ag-theme-alpine" style={{ height: "800px", width: "100%" }}>
      <AgGridReact
        defaultColDef={{ resizable: true }}
        columnDefs={columnDefs}
        rowData={rowData}
        suppressMenuHide={true}
        treeData={true}
        animateRows={true}
        groupDefaultExpanded={-1}
        autoGroupColumnDef={{ minWidth: 200 }}
        enableSorting={true}
        enableFilter={true}
        onGridReady={onGridReady}
        floatingFilter={true}
        enableCellTextSelection={true}
      />
    </div>
  );
});

export default EmployeeGrid;
