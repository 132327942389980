import axios from 'api/axios';

const ROLE_URL= '/role';
const LOGIN_TOKEN_URL = '/token';
const EMPLOYEE_URL = '/employees';
const PROFILE_URL = '/profile';

const debug = true;

//Login Token
export const api_get_token = async (username, password) =>  {
    try {
        const post = { "username": username, "password": password};

        const response_token = await axios.post(LOGIN_TOKEN_URL,
            JSON.stringify(post),
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );

        debug ? console.log('response role:' + JSON.stringify(response_token)) : null;

        const accessToken = response_token?.data?.token;

        return accessToken;
        
    } catch (err) {
        if (!err?.response) {
            throw  'Server sin respuesta. Error:' + err;
        } else if (err.response?.status === 400) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'Usuario o Contraseña invalidos';
        } else if (err.response?.status === 401) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'No autorizado';
        } else {
            throw  'Error General Login' + err;
        }
    }
};


export const api_get_roles = async (accessToken) =>  {
    try {
        const response_role = await axios.get(ROLE_URL,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
              }
            }
          );
        
          debug ? console.log('response role:' + JSON.stringify(response_role)) : null;
        
          //Get first element fo array of data
        
          const roles_data = response_role?.data?.role;
          //set as list
          const roles = [roles_data];

        return roles
        
    } catch (err) {
        if (!err?.response) {
            throw  'Server sin respuesta. Error:' + err;
        } else if (err.response?.status === 400) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'Usuario o Contraseña invalidos';
        } else if (err.response?.status === 401) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'No autorizado';
        } else {
            throw  'Error General Login' + err;
        }
    }
};

//Get user info of one employee/user
export const api_get_user_info = async (accessToken) =>  {
    try {
        const response = await axios.get(PROFILE_URL,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
              }
            }
          );
        
        
          //Get first element fo array of data

          const user_data = response?.data?.data;
          
          debug ? console.log('user_data: ' + JSON.stringify(user_data)) : null;

        return user_data
        
    } catch (err) {
        if (!err?.response) {
            throw  'Server sin respuesta. Error:' + err;
        } else if (err.response?.status === 400) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'Credenciales invalidos';
        } else if (err.response?.status === 401) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'No autorizado';
        } else {
            throw  'Error General Login' + err;
        }
    }
};


//Get user info of one employee/user
export const api_generic_get = async (accessToken) =>  {
    try {
        const response = await axios.get('/employees',
            {
              params : {id:2},
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
              }
            }
          );
                  
        //   //Get first element fo array of data
        // 
       const user_data = response?.data?.data;
    
        debug ? console.log('user_data: ' + JSON.stringify(user_data)) : null;

        return user_data
        
    } catch (err) {
        if (!err?.response) {
            throw  'Server sin respuesta. Error:' + err;
        } else if (err.response?.status === 400) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'Credenciales invalidos';
        } else if (err.response?.status === 401) {
            debug ? console.log('err.response:' + JSON.stringify(err.response)) : null;
            throw  'No autorizado';
        } else {
            throw  'Error General Login' + err;
        }
    }
};




