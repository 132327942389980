// Chakra imports
import { Flex, Grid, useColorModeValue, Input } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'api/axios';
import useAuth from 'hooks/useAuth';
import CrudForm from 'components/Forms/GenericCRUDForm.js';
import { clientSchema, clientSchemaTranslation } from 'library/yupModels/yupmodels.js';
import {
  Divider,
  Box,
  Heading,
  Text,
  VStack,
  Container,
  Spinner,
  Alert,
  AlertIcon,
  Select,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

const endpoint = '/client';

function ClientList({ searchQuery, page, setPage }) {
  const [clients, setclients] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const { auth } = useAuth();
  const accessToken = auth.accessToken;

  const fetchclients = async () => {
    try {
      const response = await axios.get('/client', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
        },
      });
      console.log({ response });

      // Filter the clients based on the search query
      const filteredclients = response?.data?.data.filter(
        client =>
          client.contact_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.legal_name.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      // Calculate the total number of pages based on the filtered results
      const pageSize = 10; // Set the number of clients to display per page
      const totalclients = filteredclients.length;
      setTotalPages(Math.ceil(totalclients / pageSize));

      // Apply pagination to the filtered results
      const startIndex = (page - 1) * pageSize;
      const paginatedclients = filteredclients.slice(startIndex, startIndex + pageSize);

      return paginatedclients;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  useEffect(() => {
    fetchclients().then(client => {
      setclients(client);
    });
  }, [searchQuery, page]);

  if (clients.length === 0) {
    return <Text>No client found.</Text>;
  }

  return (
    <VStack spacing={3} align="start" w="full">
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nombre Legal</Th>
              <Th>Nombre Contacto</Th>
              <Th>Cuit</Th>
              <Th>Email</Th>
              <Th>Celular</Th>
              <Th>Tipo Factura</Th>
            </Tr>
          </Thead>
          <Tbody>
            {clients.map(client => (
              <Tr>
                <Td>{client?.legal_name}</Td>
                <Td>{client?.contact_name}</Td>
                <Td>{client?.cuit || '-'}</Td>
                <Td>{client?.email}</Td>
                <Td>{client?.cellphone}</Td>
                <Td>{client?.invoice}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box mt={4}>
        {page > 1 && (
          <Button onClick={() => setPage(page - 1)} colorScheme="blue" mr={2}>
            Previous
          </Button>
        )}
        {page < totalPages && (
          <Button onClick={() => setPage(page + 1)} colorScheme="blue">
            Next
          </Button>
        )}
      </Box>
    </VStack>
  );
}

function Client({ post }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const textColor = useColorModeValue('gray.700', 'white');
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)',
  );

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        {/* Search Box */}
        <CrudForm
          title={'Clientes'}
          endpoint={endpoint}
          validationSchema={clientSchema}
          id={id}
          post={post}
          translator={clientSchemaTranslation}
          invalid_keys={['id']}
          reload={true}
        />
      </Flex>
      <div style={{ padding: '20px 0' }}>
        {' '}
        <Divider />{' '}
      </div>
      <Flex direction="column">
        <div style={{ padding: '20px 0' }}>
          <FormControl>
            <Input
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="🔍 Buscar cliente por nombre..."
            />
          </FormControl>
        </div>
        {/* Pass searchQuery and page to clientList */}
        <ClientList searchQuery={searchQuery} page={page} setPage={setPage} />
      </Flex>
    </>
  );
}

export default Client;
