import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'api/axios.js';
import useAuth from 'hooks/useAuth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Map from 'library/googleMapReact/map.js';
// import { DropzoneArea } from 'material-ui-dropzone';
import { Dropzone, FileMosaic } from '@files-ui/react';
import { getStorage, ref, child, deleteObject, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import { storage, firestore } from 'library/firebase.js';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Text,
  Switch,
  Select,
  Heading,
} from '@chakra-ui/react';

function formatDate(dateString, isDateOnly = false) {
  // CONVERT TIMESTAMP TO FORMAT YYYY-MM-DDTHH:MM
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return isDateOnly ? `${year}-${month}-${day}` : `${year}-${month}-${day}T${hours}:${minutes}`;
}
const DropZoneCustom = ({ id, bucket }) => {
  // const files = useRef()
  const [files, setFiles] = useState([]);
  // const { id } = useParams()

  useEffect(() => {
    const fetchFiles = async () => {
      const listRef = ref(storage, `${bucket}/${id}`);
      try {
        const res = await listAll(listRef);
        const filePromises = res.items.map(itemRef =>
          getDownloadURL(itemRef).then(url => ({
            name: itemRef.name,
            url,
            downloadUrl: url,
            ref: itemRef, // Store the reference if needed for further operations
          })),
        );
        const fileList = await Promise.all(filePromises);
        setFiles(fileList);
      } catch (error) {
        console.error('ERROR WHILE LISTING', error);
      }
    };
    fetchFiles();
  }, [id, bucket, storage]);

  useEffect(() => {
    console.log('FILES CHANGE');
    console.log({ files });
  }, [files]);

  const handleFileUpload = async file => {
    console.log('File to be uploaded:', file);
    console.log({ storage });
    const fileRef = ref(storage, `${bucket}/${id}/${file.name}`);

    // Define custom metadata, if you need to set the MIME type manually
    const metadata = {
      contentType: file.type,
      // You can include more metadata properties if needed
    };

    try {
      // Start the file upload, including the metadata
      const snapshot = await uploadBytes(fileRef, file.file, metadata);
      console.log('Uploaded a blob or file!', snapshot);

      // After a successful upload, get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log('File uploaded successfully', downloadURL);

      // Update the state to include the new file
      setFiles(prevFiles => [
        ...prevFiles,
        {
          name: file.name,
          url: downloadURL,
          type: file.type,
        },
      ]);
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  }; // const handleFileUpload = async file => {
  //   console.log({ storage });
  //   // const storageRef = storage.ref();
  //   // const fileRef = storage_ref.child(file.name);
  //   console.log('Updating file:');
  //   console.log({ file });
  //   const fileRef = await ref(storage, `${bucket}/${id}/${file.name}`);

  //   try {
  //     // const snapshot = await fileRef.put(file);
  //     uploadBytes(fileRef, file).then(snapshot => {
  //       console.log('Uploaded a blob or file!');
  //     });
  //     console.log('File uploaded successfully');
  //     // const downloadURL = await snapshot.ref.getDownloadURL();
  //     setFiles([...files, file]);
  //   } catch (error) {
  //     console.error('Error uploading file: ', error);
  //   }
  // };

  const updateFiles = incommingFiles => {
    console.log('incomming files', incommingFiles);
    var new_files = incommingFiles.map(handleFileUpload);
    // setFiles([...files, incommingFiles]);
    // console.log({ new_files });
  };

  const handleFileDelete = async fileName => {
    console.log('Deleting file:');
    console.log({ fileName });
    const fileRef = ref(storage, `${bucket}/${id}/${fileName.name}`);
    try {
      const deletion = await deleteObject(fileRef);
      console.log({ deletion });
      console.log('File deleted successfully');
      setFiles(files.filter(file => file.name !== fileName));
    } catch (error) {
      console.error('Error deleting file: ', error);
    }
  };

  return (
    <Dropzone onDrop={updateFiles} onChange={updateFiles} value={files}>
      {files.map(file => (
        <FileMosaic
          key={file.name}
          {...file}
          onDelete={() => {
            handleFileDelete(file.name);
            setFiles(files.filter(f => f.name !== file.name));
          }}
          info
        />
      ))}
    </Dropzone>
  );
};

const CrudForm = ({
  title,
  id,
  validationSchema,
  endpoint,
  translator,
  post,
  invalid_keys,
  parent_id,
  parent_key,
  redirectButton = null, // The redirect button object prop, default is null
  reload = null,
}) => {
  const { auth } = useAuth();
  const accessToken = auth.accessToken;

  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  // const formValues = useRef({})
  const [formValues, setFormValues] = useState({});
  const files = useRef([]);

  useEffect(() => {
    const controller = new AbortController();

    if (!post) {
      const fetchData = async () => {
        try {
          const response = await axios.get(endpoint, {
            params: { id },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${accessToken}`,
            },
          });
          setInitialValues(response.data.data);
          console.log({ response });

          if (!post && initialValues.files && initialValues.files.length > 0) {
            const fileDocs = await Promise.all(
              initialValues.files.map(fileId => firestore.collection('files').doc(fileId).get()),
            );

            const filesData = fileDocs.map(fileDoc => fileDoc.data());
            setInitialValues(prevValues => ({
              ...prevValues,
              files: filesData,
            }));
          }

          setLoading(false);

          console.log('Storage instance:', storage);
          console.log('Firestore instance:', firestore);
        } catch (error) {
          setLoading(false);
          setError(error.message);
        }
      };
      fetchData();
      return () => {
        controller.abort();
      };
    } else {
      setLoading(false);
      return () => {
        controller.abort();
      };
    }
  }, []);

  function renderField(fieldType, formikProps, fieldName, isDateOnly, isReadOnly, files) {
    if (fieldType === 'date') {
      return (
        <Input
          name={fieldName}
          type={isDateOnly ? 'date' : 'datetime-local'}
          onChange={formikProps.handleChange}
          value={formatDate(formikProps.values[fieldName], isDateOnly)}
        />
      );
    } else if (fieldType === 'boolean') {
      return <Switch name={fieldName} onChange={formikProps.handleChange} isChecked={formikProps.values[fieldName]} />;
    } else if (fieldType === 'selector') {
      const fieldTypeMeta = validationSchema.fields[fieldName].describe().meta;
      return (
        <Select name={fieldName} onChange={formikProps.handleChange} value={formikProps.values[fieldName]}>
          {fieldTypeMeta.choices.map((choice, index) => {
            return (
              <option key={index} value={choice}>
                {choice}
              </option>
            );
          })}
        </Select>
      );
    } else if (fieldType === 'file') {
      return (
        <>
          {/* {formikProps.values[fieldName] && */}
          {/*   formikProps.values[fieldName].length > 0 && */}
          {/*   formikProps.values[fieldName].map((file, index) => ( */}
          {/*     <a key={index} href={file.url} target="_blank" rel="noopener noreferrer"> */}
          {/*       View {file.name} */}
          {/*     </a> */}
          {/*   ))} */}
          {/* <DropzoneArea name={fieldName} onChange={files => formikProps.setFieldValue(fieldName, files)} /> */}
          <DropZoneCustom id={id} bucket={title || 'logistics'} />
        </>
      );
    } else {
      return (
        <Input
          name={fieldName}
          type="text"
          onChange={formikProps.handleChange}
          value={formikProps.values[fieldName]}
          isReadOnly={isReadOnly}
        />
      );
    }
  }

  const hasMetaKey = (schema, fieldName, metaKey) => {
    const field = schema.fields[fieldName];
    if (field) {
      const fieldDescription = field.describe();
      if (fieldDescription.meta && fieldDescription.meta.hasOwnProperty(metaKey)) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async (values, actions) => {
    try {
      // Check if parent_id and parent_key are set, and add them to the values object
      if (parent_id && parent_key) {
        // values[parent_key] = parseInt(parent_id);
      }
      let response;
      console.log(endpoint);

      let params = null;

      if (post) {
        if (endpoint == '/billing') {
          params = `?billed_event=${parent_id}`;
        } else if (endpoint == '/logistic') {
          params = `?event_logistics=${parent_id}`;
        } else {
          params = `?id=${id}`;
        }

        response = await axios.post(`${endpoint}${params}`, values, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${accessToken}`,
          },
        });
      } else {
        switch (endpoint) {
          case '/event':
            delete values.billing;
            delete values.assigned_employees;
            delete values.logistics;

            params = `?id=${initialValues?.id}`;
            console.log('w');
            break;

          case '/billing':
            params = `?event_id=${initialValues?.billed_event?.id}`;
            delete values.billed_event;
            break;

          case '/logistic':
            params = `?event_logistics=${initialValues?.event_logistics}`;
            delete values.event_logistics;
            console.log('l');
            break;

          default:
            break;
        }

        response = await axios.put(`${endpoint}${params}`, values, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${accessToken}`,
          },
        });
      }

      if (post && response && response.data && response.data.id && redirectButton && redirectButton.url) {
        if (endpoint == '/event') {
          window.location.href = `${redirectButton.url}/${response?.data?.id}`;
        } else {
          // if assignment dont use generated billing id
          window.location.href = `${redirectButton.url}/${parent_id}`;
        }
      } else {
        setSuccess(true);
        actions.setSubmitting(false);
      }
    } catch (error) {
      setError(error.message);
      actions.setSubmitting(false);
    }

    if (reload) {
      window.location.reload();
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(endpoint, {
        params: { id },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${accessToken}`,
        },
      });
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Alert status="info">
          <AlertIcon />
          Loading
        </Alert>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Flex>
    );
  }
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      {success && (
        <Alert status="success">
          <AlertIcon />
          Cambios realizados correctamente!
        </Alert>
      )}
      {title && (
        <Heading as="h1" size="xl" color="teal.500">
          {title}
        </Heading>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={async values => {
          try {
            console.log('VALIDATING...');
            await validationSchema.validate(values);
          } catch (error) {
            console.log(error);
          }
        }}
        onSubmit={(values, actions) => {
          console.log('READY TO SUBMIT!!!');
          setFormValues(values);
          console.log({ values });
          handleSubmit(values, actions);
        }}
      >
        {formikProps => (
          <Form>
            {Object.keys(validationSchema.fields)
              .filter(fieldName => !invalid_keys.includes(fieldName))
              .map((fieldName, index) => {
                const isDateField = validationSchema.fields[fieldName]._type === 'date';
                const isBool = validationSchema.fields[fieldName]._type === 'boolean';

                const idField = validationSchema.fields[fieldName].describe();

                //Read only property
                const isReadOnly = (idField.meta && idField.meta.readOnly) || false;

                //Date only property
                const isDateOnly = (idField.meta && idField.meta.dateOnly) || false;

                const isMap = (idField.meta && idField.meta.isMap && !post) || false;

                const isOneOf = hasMetaKey(validationSchema, fieldName, 'choices');

                const fieldType = isOneOf
                  ? 'selector'
                  : fieldName === 'file'
                  ? 'file'
                  : validationSchema.fields[fieldName]._type;

                /* console.log("FIELDS PRE RENDER!!") */
                /* console.log(fieldType, fieldName) */
                /* console.log(validationSchema) */

                return (
                  <FormControl
                    key={index}
                    mt={4}
                    isInvalid={formikProps.errors[fieldName] && formikProps.touched[fieldName]}
                  >
                    <FormLabel htmlFor={fieldName}> {translator[fieldName]} </FormLabel>

                    {renderField(fieldType, formikProps, fieldName, isDateOnly, isReadOnly, files)}

                    {isMap ? <Map address={formikProps.values[fieldName]} /> : null}
                    {/* { console.log("FILES to UPLOAD", { files }) } */}

                    <ErrorMessage name={fieldName}>{msg => <Text color="red"> {msg} </Text>}</ErrorMessage>
                  </FormControl>
                );
              })}

            <Button
              variant="outline"
              type="submit"
              isLoading={formikProps.isSubmitting}
              ml={4} // add margin-right to create space
              mt={4}
              size="lg"
            >
              <Text display={{ sm: 'none', md: 'flex', color: 'tomato' }}>Save</Text>
            </Button>

            {redirectButton && !post && (
              <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => {
                  if (window.location.href.includes('event')) {
                    window.location.href = `${redirectButton.url}/${initialValues?.billing}`;
                  } else if (window.location.href.includes('billing')) {
                    window.location.href = `${redirectButton.url}/${initialValues?.billed_event?.logistics}`;
                  } else if (window.location.href.includes('logistic')) {
                    window.location.href = `${redirectButton.url}/${initialValues?.event_logistics}`;
                  }
                }}
                size="sm"
                ml={4}
                mt={4}
                /* size="lg" */
                width="auto"
              >
                {redirectButton.name}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default CrudForm;
