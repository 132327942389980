import React, { useState } from "react";
import GoogleMapReact, { Marker } from "google-map-react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from "@chakra-ui/react";

function Map(props) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [error, setError] = useState(false);

  const handleApiLoaded = (map, maps) => {
    const geocoder = new maps.Geocoder();
    console.log({props})
    geocoder.geocode({ address: props.address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        const center = { lat: location.lat(), lng: location.lng() };
        setCenter(center);
      } else {
        console.error(
          "Geocode was not successful for the following reason: ",
          status
        );
        setError(true);
      }
    });
  };
  const Marker = () => (
    <svg
      width="50px"
      height="50px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Vivid.JS"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)">
          <g id="Icons" transform="translate(37.000000, 169.000000)">
            <g id="map-marker" transform="translate(78.000000, 468.000000)">
              <g transform="translate(10.000000, 6.000000)">
                <path
                  d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"
                  id="Shape"
                  fill="#FF6E6E"
                ></path>
                <circle
                  id="Oval"
                  fill="#0C0058"
                  fill-rule="nonzero"
                  cx="14"
                  cy="14"
                  r="7"
                ></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  return (
    <Box height="50vh" width="100%">
      {error ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>
            There was an error loading the map.
          </AlertDescription>
        </Alert>
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDMZiqFnvrVXI51pskucG9eIiGlaJ9Wo6Y" }}
          center={center}
          zoom={17.5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <Marker lat={center.lat} lng={center.lng} />
        </GoogleMapReact>
      )}
    </Box>
  );
}

export default Map;
