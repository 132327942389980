// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import useAuth from 'hooks/useAuth';
import CrudForm from "components/Forms/GenericCRUDForm.js";
import {BillingSchema, BillingSchemaTranslation} from 'library/yupModels/yupmodels.js'
const endpoint = '/billing';





function Bill({post}) {
    const { id } = useParams();
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
      "hsla(0,0%,100%,.8)",
      "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    
    const rbutton = {
      url: post ? "/create/logistic" : "/edit/logistic", 
      name: 'Logística' 
    } 
  
    
    if (post){
      return(        
        <CrudForm
        title={"Billing"}
        endpoint={endpoint}
        validationSchema={BillingSchema}
        parent_id = {id}
        parent_key={'event'}
        translator={BillingSchemaTranslation}
        post={post}
        invalid_keys={['id']}
        redirectButton = {rbutton}

        />)
      }else{
        return(        
          <CrudForm
          title={"Billing"}
          endpoint={endpoint}
          validationSchema={BillingSchema}
          id = {id}
          translator={BillingSchemaTranslation}
          post={post}
          invalid_keys={['id']}
          redirectButton = {rbutton}

          />)
    }

}
export default Bill;
