// Chakra imports
import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import useAuth from 'hooks/useAuth';
import CrudForm from 'components/Forms/GenericCRUDForm.js';
import { LogisticsSchema, LogisticsSchemaTranslation } from 'library/yupModels/yupmodels.js';
const endpoint = '/logistic';

function Logistic({ post }) {
  const { id } = useParams();

  // TODO Handle no logistics...
  // if (!post && !id) {
  //   post = true
  //   id = {}
  // }

  const textColor = useColorModeValue('gray.700', 'white');
  const bgProfile = useColorModeValue(
    'hsla(0,0%,100%,.8)',
    'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)',
  );

  const rbutton = {
    url: '/create/assignment',
    name: 'Asignación',
  };

  if (post) {
    return (
      <CrudForm
        endpoint={endpoint}
        validationSchema={LogisticsSchema}
        parent_id={id}
        parent_key={'event'}
        translator={LogisticsSchemaTranslation}
        post={post}
        invalid_keys={['id']}
        redirectButton={rbutton}
      />
    );
  } else {
    return (
      <CrudForm
        endpoint={endpoint}
        validationSchema={LogisticsSchema}
        id={id}
        translator={LogisticsSchemaTranslation}
        post={null}
        invalid_keys={['id']}
        redirectButton={rbutton}
      />
    );
  }
}
export default Logistic;
